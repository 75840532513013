var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "t-box" },
      [
        _c("h3", { staticStyle: { "text-align": "center", padding: "15px" } }, [
          _vm._v("用户注册"),
        ]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "status-icon": "",
              rules: _vm.rules,
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "用户名", prop: "username" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "username", $$v)
                    },
                    expression: "ruleForm.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "昵称", prop: "nickname" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.nickname,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "nickname", $$v)
                    },
                    expression: "ruleForm.nickname",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "手机号", prop: "phone" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "phone", $$v)
                    },
                    expression: "ruleForm.phone",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "邮箱", prop: "email" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "email", $$v)
                    },
                    expression: "ruleForm.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "is-required",
                attrs: { label: "密码", prop: "password" },
              },
              [
                _c("el-input", {
                  attrs: { type: "password", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "password", $$v)
                    },
                    expression: "ruleForm.password",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "is-required",
                attrs: { label: "确认密码", prop: "actPassword" },
              },
              [
                _c("el-input", {
                  attrs: { type: "password", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.actPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "actPassword", $$v)
                    },
                    expression: "ruleForm.actPassword",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { "text-align": "center", "padding-left": "15px" },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("注册")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: "/login" })
                      },
                    },
                  },
                  [_vm._v("立即登录")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }